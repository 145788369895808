import type { DecorPrice, DirectusGet } from '~/types'

export default function useDecorPrices () {
  const decorPrices = ref<DecorPrice[]>([])

  const pricesFetched = ref(false)

  const fetchPrices = async () => {
    const decorPricesData = await $fetch<DirectusGet<DecorPrice[]>>('/api/decor-prices')
    decorPrices.value = superjsonParse<DirectusGet<DecorPrice[]>>(decorPricesData).data
    pricesFetched.value = true
  }

  function decorSizePrice (type: string, sizeId: number): number {
    console.log(sizeId, type)
    console.log(decorPrices.value || 'none')
    console.log('get price')
    return decorPrices.value.find(p => p.decor_type === type && p.size_id === sizeId)?.price || 0
  }

  fetchPrices()

  return {
    decorPrices,
    decorSizePrice,
  }
}
