import payload_plugin_ljKcX9H4Cq from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.30.1/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_A3ScabTeDP from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_drizzle-orm@0.38.3_postgres@3_u4pvzfghprxchwflxi4ufzhlii/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_eN3TPrIKeH from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_drizzle-orm@0.38.3_postgres@3_u4pvzfghprxchwflxi4ufzhlii/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_vYiiftpxZr from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_drizzle-orm@0.38.3_postgres@3_u4pvzfghprxchwflxi4ufzhlii/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import sentry_client_6aFMGJ2ls3 from "/vercel/path0/node_modules/.pnpm/@develit-io+nuxt-sentry@0.4.2_magicast@0.3.5_rollup@4.30.1_vue@3.5.13_typescript@5.7.3_/node_modules/@develit-io/nuxt-sentry/dist/runtime/sentry.client.mjs";
import _0_siteConfig_bHwqtpWpSO from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.30.1_vite@5.4.11_@types+node@22.10.7_sass@1.77_a4fsvltiiu4pcprepruos5266y/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import navigation_repaint_client_IqPniT5PrT from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_drizzle-orm@0.38.3_postgres@3_u4pvzfghprxchwflxi4ufzhlii/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Jsdmmj6myi from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_drizzle-orm@0.38.3_postgres@3_u4pvzfghprxchwflxi4ufzhlii/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_7PiclKlQjU from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_drizzle-orm@0.38.3_postgres@3_u4pvzfghprxchwflxi4ufzhlii/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_50BRjPjiLj from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.30.1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_xVPoy1jzPA from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_drizzle-orm@0.38.3_postgres@3_u4pvzfghprxchwflxi4ufzhlii/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_oSkkaOF6NH from "/vercel/path0/node_modules/.pnpm/nuxt-easy-lightbox@1.0.2_magicast@0.3.5_rollup@4.30.1_vue@3.5.13_typescript@5.7.3_/node_modules/nuxt-easy-lightbox/dist/runtime/plugin.mjs";
import siteConfig_shw4c5DQ0j from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.30.1_vite@5.4.11_@types+node@22.10.7_sass@1.77.7_iiwhfsw2detkr23wiyuaonwaqm/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_lzvJxyYGQc from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.30.1_vite@5.4.11_@types+node@22.10.7_sass@1.77.7_iiwhfsw2detkr23wiyuaonwaqm/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_iEii5d5zKS from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.30.1_vite@5.4.11_@types+node@22.10.7_sass@1.77.7_iiwhfsw2detkr23wiyuaonwaqm/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import plugin_Lk2Nfe9RRf from "/vercel/path0/node_modules/.pnpm/nuxt-directus@5.7.0_magicast@0.3.5_rollup@4.30.1/node_modules/nuxt-directus/dist/runtime/plugin.mjs";
import plugin_client_CS8Nw8Hh9h from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.30.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import unocss_MzCDxu9LMj from "/vercel/path0/.nuxt/unocss.mjs";
import scroll_S7pZiC3d46 from "/vercel/path0/plugins/scroll.ts";
import defaults_L9HaRSwAdF from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.30.1_vite@5.4.11_@types+node@22.10.7_sass@1.77.7_iiwhfsw2detkr23wiyuaonwaqm/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  payload_plugin_ljKcX9H4Cq,
  revive_payload_client_A3ScabTeDP,
  unhead_eN3TPrIKeH,
  router_vYiiftpxZr,
  sentry_client_6aFMGJ2ls3,
  _0_siteConfig_bHwqtpWpSO,
  navigation_repaint_client_IqPniT5PrT,
  check_outdated_build_client_Jsdmmj6myi,
  chunk_reload_client_7PiclKlQjU,
  plugin_vue3_50BRjPjiLj,
  components_plugin_KR1HBZs4kY,
  prefetch_client_xVPoy1jzPA,
  plugin_oSkkaOF6NH,
  siteConfig_shw4c5DQ0j,
  inferSeoMetaPlugin_lzvJxyYGQc,
  titles_iEii5d5zKS,
  plugin_Lk2Nfe9RRf,
  plugin_client_CS8Nw8Hh9h,
  unocss_MzCDxu9LMj,
  scroll_S7pZiC3d46,
  defaults_L9HaRSwAdF
]