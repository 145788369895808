<script lang="ts" setup>
const navigationItems = [
  {
    title: 'Kategorie',
    href: '/kategorie',
  }, {
    title: 'Realizace',
    href: '/realizace',
  }, {
    title: 'O Nás',
    href: '/o-nas',
  }, {
    title: 'Kontakt',
    href: '/kontakt',
  },
]
const socialItems = [
  {
    icon: 'i-bxl-facebook-circle',
    href: 'https://www.facebook.com/',
  },
  {
    icon: 'i-bxl-instagram',
    href: 'https://www.instagram.com',
  },
  {
    icon: 'i-bxl-twitter',
    href: 'https://www.twitter.com',
  },
]

const linksItems = [
  {
    title: 'Odstoupení od smlouvy',
    href: '/odstoupeni-od-smlouvy',
    file: '/files/odstoupeni-od-smlouvy.pdf',
  },
  {
    title: 'Podmínky užití webu',
    href: '/podminky-uziti-webu',
    file: '/files/podminky-uziti-webu.pdf',
  },
  {
    title: 'Obchodní podmínky',
    href: '/obchodni-podminky',
    file: '/files/obchodni-podminky.pdf',
  },
  {
    title: 'Protokol o uplatnění reklamace',
    href: '/protokol-o-uplatneni-reklamace',
    file: '/files/protokol-o-uplatneni-reklamace.docx',
  },

]
</script>

<template>
  <div flex flex-col gap-32px py-48px>
    <div flex items-center justify-center font-inter>
      <NuxtLink v-for="item in navigationItems" :key="item.href" :to="item.href" px-20px py-8px class="text-14px lg:text-16px text-app-text-gray-light" leading-24px font-regular>
        {{ item.title }}
      </NuxtLink>
    </div>
    <div flex items-center justify-center gap-24px>
      <NuxtLink v-for="item in socialItems" :key="item.href" :to="item.href" external target="_blank">
        <Icon :name="item.icon" text="24px app-text-gray-icon" />
      </NuxtLink>
    </div>
    <div flex items-center flex-col gap-24px md:flex-row justify-center>
      <a v-for="item in linksItems" :key="item.href" :href="item.file" target="_blank" :download="`${item.title} - Kovotas`" px-20px class="text-14px text-app-text-gray-light" font-regular>
        {{ item.title }}
      </a>
    </div>
    <p text="14px lg:16px app-text-light center">
      © 2024 - {{ new Date().getFullYear() }} Kovotas s.r.o. - Všechna práva vyhrazena
    </p>
  </div>
</template>
